

















































































import { Component, Vue } from 'vue-property-decorator'
import Loader from '@bertazzoni/common/src/components/Loader.vue'
import { User, UserType } from '@bertazzoni/common/src/models/user.model'
import store from '@bertazzoni/common/src/store/index'
import { EntityType, NewEntityDto } from '@bertazzoni/common/src/models/entity.model'
import AssociationModal from '@bertazzoni/back/components/AssociationModal.vue'
import EntitiesService from '@bertazzoni/common/src/services/EntitiesService'
import TagList from '@bertazzoni/back/components/TagList.vue'
import { getLanguageList } from '@bertazzoni/common/src/helpers/utils/utils'
import CheckBox from '@bertazzoni/common/src/components/CheckBox.vue'
@Component({
  components: { Loader, CheckBox, TagList, AssociationModal }
})
export default class AddEntity extends Vue {
  private pageLoaded = false
  private language = ''
  private errorMessage: string = null
  private languageList: string[] = []
  private parentList: string[] = []
  private distributorUserTypeList: UserType[] = [UserType.distributorRep, UserType.salesRep]
  private entityList: string[] = []
  private entity: NewEntityDto = new NewEntityDto()
  private showAssociationModal = false
  private currentUser: User = store.getters['User/currentUser']
  private userType = UserType


  private readonly defaultEntity = 'bertazzoni'
  private readonly placeHolderValue = 'Select a language at least..'

  async created(): Promise<void> {
    this.entity.type = this.$route.params.entityType as EntityType
    this.languageList = await getLanguageList()
    const parentType = this.entity.getParentType()

    if (this.entity.type === 'country') {
      this.entity.parent = this.defaultEntity
    } else if (
      (this.entity.type == 'distributor' && this.currentUser.type == UserType.countryAdmin) ||
      (this.entity.type == 'superDealer' && this.currentUser.type == UserType.distributorRep) ||
      (this.entity.type == 'superDealer' && this.currentUser.type == UserType.salesRep)
    ) {
      this.entity.parent = this.currentUser.linkedEntityId[0]
    } else {
      this.entity.parent = ''
    }

    try {
      this.parentList = await EntitiesService.findEntitiesByType(parentType)
      if (this.entity.type == 'dealer' && this.currentUser.type == UserType.salesRep) {
        this.parentList.push(this.currentUser.linkedEntityId[0])
      }
    } catch (error) {
      this.errorMessage = 'Please add some entities to complete this form.'
      console.error(error)
    }

    this.pageLoaded = true
  }

  goToEntityList(): void {
    this.$router.push(`/entity-list/${this.entity.type}`)
  }

  addLanguage(): void {
    this.entity.languages.push(this.language)
  }

  async createEntity(): Promise<void> {
    if (
      this.entity.type == 'dealer' &&
      this.currentUser.type == UserType.salesRep &&
      this.entity.parent == ''
    ) {
      this.entity.parent = this.currentUser.linkedEntityId[0]
    }
    await EntitiesService.createOne(this.entity)
    this.showAssociationModal = true
  }

  goToCreateUser(): void {
    this.$router.push(`/add-user/${this.entity.type}/${this.entity.entityName}`)
  }

  isSubmitDisable(): boolean {
    return !!this.errorMessage
  }

  addingLanguage(selectedLang: string): void {
    this.entity.languages.push(selectedLang)
  }
  removeOneLanguage(selectedLang: string): void {
    this.entity.languages.splice(this.entity.languages.indexOf(selectedLang), 1)
  }
  removeAllLanguage(): void {
    this.entity.languages = []
  }
}
