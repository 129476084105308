












































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CheckBox extends Vue {
  @Prop({ required: true }) id!: string
  @Prop({ default: false }) initValue!: boolean
  @Prop({ default: false }) disabled!: boolean
  @Prop({ default: '' }) selectedId!: string
  private value = this.initValue
  private focused = false

  changeValue(): void {
    this.$emit('change-value', this.value, this.selectedId)
  }
}
