var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cursor-pointer grid relative"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],staticClass:"absolute h-5 w-5 opacity-0 red-focus",attrs:{"type":"checkbox","id":_vm.id,"disabled":_vm.disabled},domProps:{"checked":Array.isArray(_vm.value)?_vm._i(_vm.value,null)>-1:(_vm.value)},on:{"click":_vm.changeValue,"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false},"change":function($event){var $$a=_vm.value,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.value=$$a.concat([$$v]))}else{$$i>-1&&(_vm.value=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.value=$$c}}}}),_c('span',{staticClass:"inline-grid h-5 w-5 place-content-center",class:_vm.value && !_vm.focused
        ? 'bg-blackButton'
        : !_vm.value && !_vm.focused
        ? 'bg-white border border-blackButton'
        : !_vm.value && _vm.focused
        ? 'bg-white border border-redUnderline'
        : _vm.value && _vm.focused
        ? 'bg-blackButton border border-redUnderline'
        : ''},[(_vm.value)?_c('svg',{staticClass:"text-gray-300 h-4 w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","aria-hidden":"true","focusable":"false"}},[_c('path',{attrs:{"fill":"none","stroke":"currentColor","stroke-width":"3","d":"M1.73 12.91l6.37 6.37L22.79 4.59"}})]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }