






































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Icons from '@bertazzoni/common/src/components/Icons.vue'
import { TagAccess } from '@bertazzoni/common/src/models/entity.model'

@Component({
  components: { Icons }
})
export default class TagList extends Vue {
  @Prop({ default: '' }) id!: string
  @Prop({ default: () => [] }) tagList!: TagAccess[] | string[]
  @Prop({ default: () => [] }) tagToSelectList!: string[]
  @Prop({ default: false }) readonly!: boolean
  @Prop({ default: '' }) title!: string
  @Prop({ default: '' }) subTitle!: string
  @Prop({ default: () => [] }) realTagListWithoutAll!: TagAccess[]
  @Prop({ default: false }) accessFilter!: boolean
  @Prop({ default: true }) resetAll!: boolean
  @Prop({ required: false }) placeHolder?: string
  // props placeholder optionel
  private placeHolderValue = ''
  private tagSearch = ''
  private showTagList = false

  created() {
    this.placeHolderValue = this.placeHolder || 'Select your tag'
  }

  deleteTag(index: number): void {
    this.$emit('delete-tag', index)
  }
  deleteAllTag(): void {
    this.$emit('delete-all-tag')
  }
  selectTag(tag: string): void {
    this.$emit('select-tag', tag)
    this.showTagList = false
  }

  get filteredList(): string[] {
    let autoCompList = []
    if (this.tagSearch && this.tagSearch !== '') {
      autoCompList = this.tagToSelectList.filter(
        (e) => e.toLowerCase().indexOf(this.tagSearch.toLowerCase()) !== -1
      )
    } else {
      autoCompList = this.tagToSelectList
    }
    if (this.tagList && this.tagList.length > 0) {
      return autoCompList.filter((item) => {
        if (this.accessFilter) {
          return (
            !(this.tagList as TagAccess[]).map((e: TagAccess) => e._id).includes(item) &&
            !this.realTagListWithoutAll.map((e: TagAccess) => e._id).includes(item)
          )
        } else {
          return !(this.tagList as string[]).includes(item)
        }
      })
    } else {
      return autoCompList
    }
  }

  @Watch('filteredList')
  getfilteredAutoComp(newValue: string[]) {
    this.$emit('new-filtered-autoComp', newValue)
  }
}
